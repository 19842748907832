import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Image from "../../../components/image";
import Title from "../../Base/Title";
import background from "../../../images/global_assets/semi-circles-pattern.png";
import useStartMotion from "../../../hooks/use-start-motion";

const variants = {
  hidden: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const PartnersTitle = motion(
  React.forwardRef((props, ref) => (
    <div className="max-w-1440 my-0 mx-auto" ref={ref}>
      <Title
        level={2}
        className="relative px-4 lg:px-0 text-center lg:text-left lg:w-1/2 xl:w-1/3 lg:ml-16 xl:ml-32 lg:mb-20"
        withBlueSymbol
      >
        Join these businesses using SpotOn
      </Title>
    </div>
  )),
  { forwardMotionProps: true }
);

const PartnersLogos = motion(
  React.forwardRef(
    (props, ref) => (
      <div
        ref={ref}
        className={`
          max-w-1440 grid my-0 mx-auto
          grid-cols-2 gap-16 
          px-4 lg:px-8 
          md:grid-cols-8
          `}
        align="middle"
        justify="center"
        style={props.styles || { margin: "70px 20px 70px 20px" }}
      >
        <Image
          imageName="thunderdome-logo.png"
          className="local-logo self-center"
        />

        <Image imageName="neai-logo.png" className="local-logo self-center" />

        <Image
          imageName="celebrity-nails-logo.png"
          className="local-logo self-center"
        />

        <Image imageName="LoDo-logo.png" className="local-logo self-center" />

        <Image
          imageName="waukesha-logo.png"
          className="local-logo self-center"
        />

        <Image
          imageName="coohills_logo.png"
          className="local-logo self-center"
        />

        <Image
          imageName="public-house-logo.png"
          className="local-logo self-center"
        />

        <Image
          imageName="mile-high-logo.png"
          className="local-logo self-center"
        />
      </div>
    ),
    {
      forwardMotionProps: true,
    }
  )
);

const PartnerLogos = (props) => {
  const { styles, animations } = props;
  const { ref, controls } = useStartMotion();

  return (
    <section className="relative w-full mx-4 lg:mx-8 mt-20 lg:mt-6" ref={ref}>
      <img
        className="partner-logos__background absolute right-0 top-16 md:-top-16"
        src={background}
        alt="background"
      />

      <PartnersTitle
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.3, duration: 0.8 }}
      />

      <PartnersLogos
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={variants}
        transition={{ delay: 0.6, duration: 0.8 }}
        styles={styles}
      />

      <style jsx>{`
        :global(.local-logo img) {
          width: 100%;
          height: auto;
        }
      `}</style>
    </section>
  );
};

PartnerLogos.propTypes = {
  styles: PropTypes.objectOf(PropTypes.string),
  animations: PropTypes.bool,
};
PartnerLogos.defaultProps = {
  styles: {},
  animations: true,
};

export default PartnerLogos;
